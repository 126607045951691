import axios from 'axios';
import config from "../config";


export const emailGuidelines = async (data: { sessionId: string, email: string }) => {
    try {
        const response = await axios.post(`${config.apiUrl}/v1/brand/session/complete`, data);
        return response.data;
    } catch (error) {
        console.error('Error posting industry:', error);
        throw error;
    }
};
