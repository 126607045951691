import React from 'react';
import {AppBar, Box, IconButton, Toolbar} from '@mui/material';
import ArrowBackIconIos from '@mui/icons-material/ArrowBackIos';
import logo from '../assets/text_logo.png';
import {useDispatch, useSelector} from 'react-redux';
import {previousStep} from '../store/stepSlice'; // Update the path to your logo

const NavBar: React.FC = () => {
    const step = useSelector((state: any) => state.step.step);
    const dispatch = useDispatch();

    function goBack() {
        dispatch(previousStep());
    }

    function handleLogoClick() {
        const userConfirmed = window.confirm("Are you sure you want to refresh the page? Any unsaved changes will be lost.");
        if (userConfirmed) {
            window.location.reload();
        }
    }

    return (
        <AppBar position="static" sx={{backgroundColor: '#F7F7F8', boxShadow: 'none'}}>
            <Toolbar>
                {(step > 1 && step !== 6) ? (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="back"
                        sx={{
                            padding: 0, // Reset padding
                            margin: 0, // Reset margin
                            minWidth: {
                                xs: '40px',
                                sm: '40px',
                                md: '80px',
                                lg: '80px',
                                xl: '80px'
                            },
                            minHeight: {
                                xs: '40px',
                                sm: '40px',
                                md: '60px',
                                lg: '60px',
                                xl: '60px'
                            },
                            paddingLeft: {xs: '10px', sm: '20px', md: '40px', lg: '40px', xl: '40px'},
                            '&:hover': {
                                backgroundColor: 'transparent'
                            },
                            color: '#6A6A6A',
                            zIndex: 10,
                        }}
                        onClick={goBack}
                    >
                        <ArrowBackIconIos fontSize="small"/>
                    </IconButton>
                ) : null}
                <Box flexGrow={1}/>
                <img
                    src={logo}
                    alt="Blursday Logo"
                    style={{height: '40px', paddingRight: '10px', cursor: 'pointer'}} // Adjust padding for mobile
                    onClick={handleLogoClick}
                />
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;
