import { configureStore } from '@reduxjs/toolkit';
import brandReducer from './brandSlice';
import stepReducer from './stepSlice';
import sessionReducer from './sessionSlice';

const store = configureStore({
    reducer: {
        brand: brandReducer,
        session: sessionReducer,
        step: stepReducer
    }
});

export default store;
