import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setCompanyName} from '../store/brandSlice';
import {Box} from '@mui/material';
import CenteredContainer from '../components/CenteredContainer';
import StyledButton from '../components/StyledButton';
import StyledForm from '../components/StyledForm';
import Heading3Component from "../components/Heading3";
import Body1Component from "../components/Body1";
import {recordAnswer} from "../services/brandAnswer";
import StyledTextField from "../components/StyledTextField";

interface BrandGeneratorProps {
    onNext: () => void;
}

const commonHeight = '56px';
const borderRadius = '10px';

const BrandGenerator: React.FC<BrandGeneratorProps> = ({onNext}) => {
    const dispatch = useDispatch();
    const question = useSelector((state: any) => state.session.questions[0]);
    const sessionId = useSelector((state: any) => state.session.sessionId);
    const storedCompanyName = useSelector((state: any) => state.brand.companyName); // Get company name from Redux store

    const [name, setName] = useState(storedCompanyName || '');

    useEffect(() => {
        setName(storedCompanyName || '');
    }, [storedCompanyName]);

    const handleSubmit = () => {
        dispatch(setCompanyName(name));
        recordAnswer({
            sessionId: sessionId,
            questionId: question.questionId,
            answer: name
        }).catch(e => console.log(e));
        onNext();
    };

    return (
        <CenteredContainer>
            <Box sx={{width: '100%'}}>
                <Heading3Component sx={{paddingBottom: '30px'}}>
                    Ready to build a brand that doesn’t suck?
                </Heading3Component>
                <Body1Component sx={{paddingBottom: '60px'}}>
                    Blursday’s brand guidelines generator is like your cool designer friend, but faster (and cheaper).
                    <br/>
                    <br/>
                    Just drop a name, click around, and bam, you’re done in five. Easy peasy!
                </Body1Component>
                <StyledForm>
                    <StyledTextField
                        placeholder={question.question}
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            backgroundColor: !name.trim() ? '#F7F7F8' : 'inherit',
                        }}
                    />
                    <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!name.trim()}
                        sx={{height: commonHeight, borderRadius: borderRadius, width: '9vw', marginTop: '15px'}}
                    >
                        Get started
                    </StyledButton>
                </StyledForm>
            </Box>
        </CenteredContainer>
    );
};

export default BrandGenerator;
