import { styled } from "@mui/system";
import { TextField } from "@mui/material";

const borderRadius = '10px';

const StyledTextField = styled(TextField)(({ theme }) => ({
    flex: 1,
    borderRadius: borderRadius,
    '& .MuiOutlinedInput-root': {
        borderRadius: borderRadius,
        '& fieldset': {
            borderColor: theme.palette.grey[400], // Default border color
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main, // Border color on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.main, // Border color when focused
        },
    },
    '& .MuiInputLabel-root': {
        color: '#6A6A6A', // Label color
        transition: 'transform 0.2s, font-size 0.2s',
        whiteSpace: 'normal',
        overflowWrap: 'break-word'
    },
    '& .MuiInputLabel-shrink': {
        color: '#6A6A6A', // Ensure label color remains the same when shrunk
    },
    '& .MuiInputBase-root': {
        '& .MuiInputBase-input': {
            // Ensure padding doesn't overlap with border
            paddingTop: '16px',
        },
        '&:focus .MuiInputLabel-root': {
            display: 'none', // Hide label on focus
        },
        overflow: 'auto'
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)', // Adjust position when shrunk
    },
}));

export default StyledTextField;
