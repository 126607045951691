import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setAboutBrand} from '../store/brandSlice';
import CenteredContainer from '../components/CenteredContainer';
import StyledButton from '../components/StyledButton';
import StyledForm from '../components/StyledForm';
import Heading3Component from "../components/Heading3";
import Body1Component from "../components/Body1";
import {recordAnswer} from "../services/brandAnswer";
import {Box, CircularProgress} from "@mui/material";
import StyledTextField from "../components/StyledTextField";
import {getPrefilledStatements} from "../services/getPrefilledStatements";

interface AboutBrandProps {
    onNext: () => void;
}

const commonHeight = '56px';
const borderRadius = '10px';

const AboutBrand: React.FC<AboutBrandProps> = ({onNext}) => {
    const dispatch = useDispatch();
    const question = useSelector((state: any) => state.session.questions[4]);
    const sessionId = useSelector((state: any) => state.session.sessionId);
    const companyName = useSelector((state: any) => state.brand.companyName);
    const storedAboutBrand = useSelector((state: any) => state.brand.aboutBrand); // Get about brand from Redux store

    const [vision, setVision] = useState(storedAboutBrand || '');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setVision(storedAboutBrand || '');
    }, [storedAboutBrand]);

    const handleSubmit = async () => {
        setLoading(true);
        dispatch(setAboutBrand(vision));
        try {
            await recordAnswer({
                sessionId: sessionId,
                questionId: question.questionId,
                answer: vision
            });
            await getPrefilledStatements(sessionId);
            onNext();
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    return (
        <CenteredContainer>
            <Box sx={{width: '100%'}}>
                <Heading3Component sx={{paddingBottom: '30px'}}>
                    Hi {companyName}!
                </Heading3Component>
                <Body1Component>
                    {question.question}
                </Body1Component>
                <Body1Component sx={{paddingBottom: '60px', marginTop: '20px', fontSize: '20px'}}>
                    What’s the magic trick your brand pulls off? Don’t be shy—brag a little. We promise we’ll be
                    impressed.
                </Body1Component>
                <StyledForm>
                    <StyledTextField
                        placeholder="Spill the tea. The more you tell us, the better we can make you look!"
                        variant="outlined"
                        value={vision}
                        onChange={(e) => setVision(e.target.value)}
                        multiline
                        rows={6}
                        sx={{
                            backgroundColor: !vision.trim() ? '#F7F7F8' : 'inherit'
                        }}
                    />
                </StyledForm>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!vision.trim() || loading}
                    sx={{height: commonHeight, borderRadius: borderRadius}}
                >
                    {loading ? <CircularProgress size={24} /> : 'Next'}
                </StyledButton>
            </Box>
        </CenteredContainer>
    );
};

export default AboutBrand;
