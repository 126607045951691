import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import {Provider} from 'react-redux';
import App from "./App";
import './pages/brand_options/svg-colours.css';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <div>
                <App/>
            </div>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
