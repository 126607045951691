import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import CenteredContainer from '../../components/CenteredContainer';
import Heading3Component from "../../components/Heading3";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography
} from "@mui/material";
import Body1Component from "../../components/Body1";
import Heading5Component from "../../components/Heading5";
import StyledButton from "../../components/StyledButton";
import {chooseGuidelines} from "../../services/chooseGuidelines";
import {emailGuidelines} from "../../services/endSession";
// @ts-ignore
import Microlink from "@microlink/react";
import {
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon
} from "react-share";
import SVG1 from '../../assets/templates/undraw_before_dawn_re_hp4m.svg'; // Add your SVG imports here
import SVG2 from '../../assets/templates/undraw_meet_the_team_re_4h08.svg';
import SVG4 from '../../assets/templates/undraw_polaroid_re_481f.svg';
import SVG3 from '../../assets/templates/undraw_product_hunt_n-3-f5.svg';
import './svg-colours.css';
import SvgLoader from "../../Svg";
import addToFigma from '../../assets/add_to_figma.png';

interface BrandOverviewProps {
    onNext: () => void;
}

const BrandOverview: React.FC<BrandOverviewProps> = ({onNext}) => {
    const companyName = useSelector((state: any) => state.brand.companyName);
    const about = useSelector((state: any) => state.brand.aboutBrand)
    const colourPalette = useSelector((state: any) => state.session.colourPalette);
    const fontPalette = useSelector((state: any) => state.session.fontPalette.brand);
    const illustrationOption = useSelector((state: any) => state.session.illustrationOption);
    const iconographyOption = useSelector((state: any) => state.session.setIconographyOption);

    const sessionId = useSelector((state: any) => state.session.sessionId);

    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [loading, setLoading] = useState(false);  // Loading state for email submission

    useEffect(() => {
        const options = {
            colorOptions: colourPalette,
            fonts: {brand: fontPalette, product: fontPalette},
            illustrations: illustrationOption,
            iconography: iconographyOption,
        };

        chooseGuidelines({sessionId: sessionId, options: options})
            .then(response => {
                console.log("Guidelines chosen successfully:", response);
            })
            .catch(error => {
                console.error("Error choosing guidelines:", error);
            });
    }, [colourPalette, fontPalette, illustrationOption, iconographyOption, companyName, sessionId]);

    useEffect(() => {
        // Update CSS variables with the colors from the Redux store
        if (colourPalette) {
            const primaryColors = colourPalette.primary;
            const secondaryColors = colourPalette.secondary;

            // Safely set CSS variables with fallback to the first color or a default value
            document.documentElement.style.setProperty('--primary_1', primaryColors[0] || '#000000');
            document.documentElement.style.setProperty('--primary_2', primaryColors[1] || primaryColors[0] || '#000000');
            document.documentElement.style.setProperty('--primary_3', primaryColors[2] || primaryColors[0] || '#000000');

            document.documentElement.style.setProperty('--accent_1', secondaryColors[0] || '#FFFFFF');
            document.documentElement.style.setProperty('--accent_2', secondaryColors[1] || secondaryColors[0] || '#FFFFFF');
        }
    }, [colourPalette]);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsButtonClicked(false);
    };

    const handleEmailSubmit = async () => {
        setIsButtonClicked(true);
        setLoading(true);  // Set loading state to true
        emailGuidelines({sessionId: sessionId, email: email}).then(() => {
            setEmailSent(true);
            setErrorMessage('');
        }).catch(() => {
            setErrorMessage('Failed to send email. Please try again.');
        }).finally(() => {
            setLoading(false);  // Set loading state to false
            setOpen(false);
        });
    };

    const handleStartOver = () => {
        window.location.reload();
    };

    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setEmail(email);
        setIsEmailValid(validateEmail(email));
    };

    const renderColorCard = (category: string, colors: string[]) => (
        <Card sx={{minWidth: '275px', margin: '10px', flexGrow: 1, maxWidth: '400px'}}>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="textSecondary" gutterBottom>
                    {category} Colours
                </Typography>
                <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    {colors.map((color, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '30%',
                                height: {
                                    xs: '115px',    // Extra-small screens
                                    sm: '140px',    // Small screens
                                    md: '180px',    // Medium screens
                                    lg: '180px',    // Large screens
                                    xl: '180px'     // Extra-large screens
                                },
                                backgroundColor: color,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                margin: '5px'
                            }}
                        >
                            <Box
                                sx={{
                                    width: '80%',
                                    height: '80%',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '7%'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '80%',
                                        height: '80%',
                                        backgroundColor: color,
                                    }}
                                />
                                <Typography
                                    variant="caption"
                                    sx={{color: '#333', marginY: '5%', fontWeight: 'bold'}}
                                >
                                    {color}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );

    const renderFontCard = (category: string, font: any) => (
        <Card sx={{minWidth: '275px', margin: '10px', flexGrow: 1, maxWidth: '500px'}}>
            <CardContent>
                <Typography sx={{fontSize: 14}} color="textSecondary" gutterBottom>
                    {category} Font
                </Typography>
                <Typography variant="h6" component="div">
                    {font.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {font.explanation}
                </Typography>
            </CardContent>
        </Card>
    );

    const renderUsageCard = (svg: string, altText: string) => (
        <Card sx={{minWidth: '275px', margin: '10px', flexGrow: 1, maxWidth: '300px'}}>
            <CardContent>
                <Box sx={{display: 'flex', justifyContent: 'center', maxWidth: '100%', maxHeight: 'auto'}}>
                    <SvgLoader src={svg} alt={altText} style={{maxWidth: '100%', height: '100%'}}/>
                </Box>
            </CardContent>
        </Card>
    );

    return (
        <CenteredContainer>
            <Box sx={{width: '100%'}}>
                <Heading3Component sx={{paddingBottom: '30px'}}>
                    Voilà! Here's your brand’s starting kit, {companyName}!
                </Heading3Component>
                <Body1Component sx={{paddingBottom: '30px'}}>
                    Lookin’ good! These colors, fonts, and illustrations are a solid start, but let’s be real—there’s
                    way more to a brand than just picking out a few pretty things.
                    <br/>
                </Body1Component>
                {emailSent ? (
                    <>

                        {/* Container to align buttons in one line */}
                    </>
                ) : (
                    <>
                        <Body1Component>
                            So, let us email the brand book to you!
                        </Body1Component></>
                )}
                <Box sx={{display: 'column', justifyContent: 'center', marginBottom: '50px'}}>
                    {emailSent ? (
                        <>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#2255FF',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontWeight: 'bold',
                                }}
                            >
                                A brand book has been emailed to you. You can start using the guidelines to review and
                                generate content:
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '9px', // Add space between buttons
                                    marginTop: '19px',
                                }}
                            >
                                <a
                                    href="https://slack.com/oauth/v2/authorize?client_id=6041204973751.7310648280193&scope=app_mentions:read,chat:write,commands,files:read,users:read,users:read.email&user_scope="
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt="Add to Slack"
                                        height="39"
                                        width="138"
                                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                                    />
                                </a>
                                <a
                                    href="https://www.figma.com/community/plugin/1358848983645897435/review-raven-ai-design-review-partner"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        alt="Add to Figma"
                                        height="39"
                                        width="138"
                                        src={addToFigma}
                                    />
                                </a>
                            </Box>
                            {/*
                            <StyledButton
                                variant="contained"
                                color="secondary"
                                onClick={handleStartOver}
                                sx={{marginTop: '20px', justifyContent: 'center', width: '12vw'}}
                            >
                                Or start over
                            </StyledButton>
*/}
                        </>
                    ) : (
                        <>
                            <StyledButton
                                variant="contained"
                                color="primary"
                                onClick={handleClickOpen}
                                sx={{width: '15vw', marginTop: '0px'}}
                            >
                                Use these guidelines
                            </StyledButton>
                            {errorMessage && (
                                <Typography variant="body2" color="error" sx={{marginTop: '10px'}}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', gap: 2, marginBottom: '40px'}}>
                    <Body1Component>
                        Brag about your brand building skills on social media:
                    </Body1Component>
                    <TwitterShareButton url={`https://app.blursday.wtf`}
                                        title={`I am building ${companyName}. ${about}. I just built my brand book on Blursday in under 5 minutes, and at $0. You can build yours too. Just click and get started\n`}>
                        <XIcon size={32} round/>
                    </TwitterShareButton>
                    <LinkedinShareButton url={`https://app.blursday.wtf`}
                                         title={`I am building ${companyName}. ${about}. I just built my brand book on Blursday in under 5 minutes, and at $0. You can build yours too. Just click and get started\n`}
                                         summary={`I am building ${companyName}. ${about}. I just built my brand book on Blursday in under 5 minutes, and at $0. You can build yours too. Just click and get started\n`}>
                        <LinkedinIcon size={32} round/>
                    </LinkedinShareButton>
                    <WhatsappShareButton url={"https://app.blursday.wtf"}
                                         title={`I am building ${companyName}. ${about}. I just built my brand book on Blursday in under 5 minutes, and at $0. You can build yours too. Just click and get started\n`}>
                        <WhatsappIcon size={32} round/>
                    </WhatsappShareButton>
                </Box>
                <Box sx={{marginBottom: '40px'}}>
                    {/* Add the SVG cards here */}
                    <Heading5Component>Here's how you can use the colours:</Heading5Component>
                    <Box sx={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '20px'}}>
                        {renderUsageCard(SVG1, 'Usage 1')}
                        {renderUsageCard(SVG2, 'Usage 2')}
                        {renderUsageCard(SVG3, 'Usage 3')}
                        {renderUsageCard(SVG4, 'Usage 4')}
                    </Box>
                    <Heading5Component>Colors</Heading5Component>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                        {renderColorCard('Primary', colourPalette.primary)}
                        {renderColorCard('Secondary', colourPalette.secondary)}
                    </Box>
                </Box>
                <Box sx={{marginBottom: '40px'}}>
                    <Heading5Component>Fonts</Heading5Component>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                        {renderFontCard('Primary', fontPalette.primary)}
                        {renderFontCard('Secondary', fontPalette.secondary)}
                    </Box>
                </Box>
                <Box sx={{marginBottom: '40px'}}>
                    <Heading5Component>Illustrations and Iconography</Heading5Component>
                    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center'}}>
                        <Card sx={{minWidth: '275px', margin: '10px', flexGrow: 1, maxWidth: '500px'}}>
                            <CardContent>
                                <Typography sx={{fontSize: 14}} color="textSecondary" gutterBottom>
                                    Illustrations
                                </Typography>
                                <Typography variant="h6" component="div">
                                    {illustrationOption.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {illustrationOption.description}
                                </Typography>
                                <Microlink url={illustrationOption.link}
                                           sx={{width: '100%', height: '200px', border: 'none'}}/>
                            </CardContent>
                        </Card>
                        <Card sx={{minWidth: '275px', margin: '10px', flexGrow: 1, maxWidth: '500px'}}>
                            <CardContent>
                                <Typography sx={{fontSize: 14}} color="textSecondary" gutterBottom>
                                    Iconography
                                </Typography>
                                <Typography variant="h6" component="div">
                                    {iconographyOption.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    {iconographyOption.description}
                                </Typography>
                                <Microlink url={iconographyOption.link}
                                           sx={{width: '100%', height: '200px', border: 'none'}}/>
                            </CardContent>
                        </Card>
                    </Box>
                </Box>
            </Box>

            <Dialog open={open} onClose={handleClose} PaperProps={{style: {borderRadius: 20, padding: '20px'}}}>
                <DialogTitle
                    sx={{fontWeight: 'bold', paddingBottom: 0, fontFamily: 'Montserrat, sans-serif', fontSize: '20px'}}>
                    Email your brand book
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{
                            paddingBottom: '10px',
                            fontFamily: 'Montserrat, sans-serif',
                            familySize: '15px',
                            color: '#424242'
                        }}>
                        Please enter your email address to receive a copy of your brand book.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        placeholder="YOUR EMAIL ID"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={email}
                        onChange={handleEmailChange}
                        sx={{
                            fontFamily: 'Montserrat, sans-serif',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'transparent',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'transparent',
                                },
                                backgroundColor: '#E0E0E0',
                                borderRadius: '10px',
                            },
                        }}
                    />
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'}, // Column layout on xs screens
                        gap: 1 // Gap between buttons
                    }}
                >
                    <Button onClick={handleClose}
                            sx={{
                                color: '#2979FF',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '16px',
                                fontWeight: 'bold'
                            }}>
                        Cancel
                    </Button>
                    <StyledButton onClick={handleEmailSubmit} variant="contained" sx={{
                        marginTop: {
                            xs: '0px',
                            sm: '0',
                            md: '17px',    // Medium screens
                            lg: '17px',    // Large screens
                            xl: '17px'
                        }, // Margin-top on xs screens
                    }} disabled={!isEmailValid || isButtonClicked}>
                        {loading ? <CircularProgress size={24}/> : 'Send'}
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </CenteredContainer>
    );
};

export default BrandOverview;
