import {styled} from "@mui/system";
import {Container} from "@mui/material";

const CenteredContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    width: '90vw',
    textAlign: 'center',
    paddingTop: '18vh'
});

export default CenteredContainer;