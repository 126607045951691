import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenteredContainer from '../../components/CenteredContainer';
import Heading3Component from "../../components/Heading3";
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import Body1Component from "../../components/Body1";
import Heading5Component from "../../components/Heading5";
import StyledButton from "../../components/StyledButton";
import { setColourPalette } from "../../store/sessionSlice";

interface ColourOptionsProps {
    onNext: () => void;
}

type ColorOptions = {
    primary: string[],
    secondary: string[]
};

type SelectedCardState = {
    primary: { optionIndex: number | null, colors: string[] | null },
    secondary: { optionIndex: number | null, colors: string[] | null }
};

const ColourOptions: React.FC<ColourOptionsProps> = ({ onNext }) => {
    const [selectedCard, setSelectedCard] = useState<SelectedCardState>({
        primary: { optionIndex: null, colors: null },
        secondary: { optionIndex: null, colors: null }
    });
    const [allSelected, setAllSelected] = useState(false);

    const sessionId = useSelector((state: any) => state.session.sessionId);
    const companyName = useSelector((state: any) => state.brand.companyName);
    const colorOptions = useSelector((state: any) => state.session.brandOptions.colorOptions);
    const dispatch = useDispatch();

    useEffect(() => {
        const { primary, secondary } = selectedCard;
        if (primary.colors || secondary.colors) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [selectedCard]);

    function handleClick(optionIndex: number, category: keyof ColorOptions, colors: string[]) {
        setSelectedCard(prevState => ({
            ...prevState,
            [category]: {
                optionIndex,
                colors
            }
        }));
    }

    const renderColorCard = (optionIndex: number, category: keyof ColorOptions, colors: string[]) => (
        <Card
            sx={{
                minWidth: '275px',
                margin: '10px',
                flexGrow: 1,
                maxWidth: '420px',
                border: selectedCard[category].optionIndex === optionIndex && selectedCard[category].colors === colors ? '2px solid blue' : 'none'
            }}
        >
            <CardContent onClick={() => handleClick(optionIndex, category, colors)}>
                <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                    {category === 'secondary' ? 'Accent Colours' : category.charAt(0).toUpperCase() + category.slice(1) + ' Colours'}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {colors.map((color, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '30%',
                                height: {
                                    xs: '115px',    // Extra-small screens
                                    sm: '140px',    // Small screens
                                    md: '180px',    // Medium screens
                                    lg: '180px',    // Large screens
                                    xl: '180px'     // Extra-large screens
                                },
                                backgroundColor: color,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                margin: '5px',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '80%',
                                    height: '80%',
                                    backgroundColor: 'white',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: '7%'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '80%',
                                        height: '80%',
                                        backgroundColor: color,
                                    }}
                                />
                                <Typography
                                    variant="caption"
                                    sx={{ color: '#333', marginY: '5%', fontWeight: 'bold' }}
                                >
                                    {color}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );

    const renderColorOptions = (options: ColorOptions, optionIndex: number) => (
        <Box key={optionIndex} sx={{ marginBottom: '40px' }}>
            <Heading5Component>
                Option {optionIndex + 1}
            </Heading5Component>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignContent: 'center' }}>
                {renderColorCard(optionIndex, 'primary', options.primary)}
                {renderColorCard(optionIndex, 'secondary', options.secondary)}
            </Box>
        </Box>
    );

    function handleSubmit() {
        const { primary, secondary } = selectedCard;

        // Auto-select the second color if only one is selected
        if (!primary.colors) {
            const firstAvailableOption = colorOptions?.option1.primary;
            setSelectedCard(prevState => ({
                ...prevState,
                primary: {
                    optionIndex: 0,
                    colors: firstAvailableOption || []
                }
            }));
        }

        if (!secondary.colors) {
            const firstAvailableOption = colorOptions?.option1.secondary;
            setSelectedCard(prevState => ({
                ...prevState,
                secondary: {
                    optionIndex: 0,
                    colors: firstAvailableOption || []
                }
            }));
        }

        dispatch(setColourPalette({
            primary: primary.colors || colorOptions?.option1.primary,
            secondary: secondary.colors || colorOptions?.option1.secondary
        }));
        onNext();
    }

    return (
        <CenteredContainer>
            <Box sx={{ width: '100%' }}>
                <Heading3Component sx={{ paddingBottom: '30px' }}>
                    Hi {companyName}!
                </Heading3Component>
                {colorOptions ? (
                    <Box>
                        <Body1Component sx={{ paddingBottom: '30px' }}>
                            Choose your favorite primary and accent colors from the three options below.
                            (Put those fingers to work and consider scrolling to see all the options.)
                        </Body1Component>
                        <Box>
                            {renderColorOptions(colorOptions.option1, 0)}
                            {renderColorOptions(colorOptions.option2, 1)}
                            {renderColorOptions(colorOptions.option3, 2)}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <StyledButton
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={!allSelected}
                            >
                                Next
                            </StyledButton>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Body1Component sx={{ paddingBottom: '60px' }}>
                            We are generating your brand options. This may take a few seconds.
                        </Body1Component>
                        <CircularProgress />
                    </>
                )}
            </Box>
        </CenteredContainer>
    );
};

export default ColourOptions;
