import axios from 'axios';
import config from '../config';
import {setQuestions, setSessionId} from '../store/sessionSlice';
import store from "../store/store";

export const startSession = async () => {
    try {
        const response = await axios.post(`${config.apiUrl}/v1/brand/session/start`);

        /*                const response = {
                            data: {
                                data: {
                                    questions: [
                                        {
                                            question: 'What is the name of your brand?',
                                            questionId: 'name',
                                            inputType: 'string'
                                        },
                                        {
                                            question: 'What is the domain in which your brand operates?',
                                            questionId: 'domain',
                                            inputType: 'string'
                                        },
                                        {
                                            question: 'What\'s your mission statement?',
                                            questionId: 'mission_statement',
                                            inputType: 'string'
                                        },
                                        {
                                            question: 'What\'s your vision statement?',
                                            questionId: 'vision_statement',
                                            inputType: 'string'
                                        },
                                        {
                                            question: 'What does your brand do?',
                                            questionId: 'what_do_we_do',
                                            inputType: 'string'
                                        }
                                    ],
                                    sessionId: 'session'
                                }
                            }
                        }*/
        const questions = response.data.data.questions.map((q: any) => ({
            question: q.question,
            questionId: q.questionId,
            inputType: q.inputType as 'string' | 'image'
        }));

        store.dispatch(setSessionId(response.data.data.sessionId));
        store.dispatch(setQuestions(questions));
        return response.data;
    } catch (error) {
        console.error('Error starting session:', error);
        return null;
    }
}