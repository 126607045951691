// src/store/sessionSlice.ts
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface Question {
    question: string;
    questionId: string;
    inputType: 'string' | 'image';
}

interface SessionState {
    sessionId: string | null;
    questions: Question[];
    brandOptions: any;
    colourPalette: any;
    fontPalette: any;
    illustrationOption: any;
    setIconographyOption: any;
}

const initialState: SessionState = {
    sessionId: null,
    questions: [],
    brandOptions: null,
    colourPalette: null,
    fontPalette: null,
    illustrationOption: null,
    setIconographyOption: null
};

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSessionId(state, action: PayloadAction<string>) {
            state.sessionId = action.payload;
        },
        setQuestions(state, action: PayloadAction<Question[]>) {
            state.questions = action.payload;
        },
        setBrandOptions(state, action) {
            state.brandOptions = action.payload;
        },
        setColourPalette(state, action) {
            state.colourPalette = action.payload;
        },
        setFontPalette(state, action) {
            state.fontPalette = action.payload;
        },
        setIllustrationOption(state, action) {
            state.illustrationOption = action.payload;
        },
        setIconographyOption(state, action) {
            state.setIconographyOption = action.payload;
        },
    },
});

export const {setSessionId, setQuestions, setBrandOptions, setColourPalette, setFontPalette, setIllustrationOption, setIconographyOption} = sessionSlice.actions;
export default sessionSlice.reducer;