import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    companyName: '',
    industry: '',
    missionStatement: '',
    visionStatement: '',
    aboutBrand: '',
};

const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        setCompanyName: (state, action) => {
            state.companyName = action.payload;
        },
        setIndustry: (state, action) => {
            state.industry = action.payload;
        },
        setMissionStatement: (state, action) => {
            state.missionStatement = action.payload;
        },
        setVisionStatement: (state, action) => {
            state.visionStatement = action.payload;
        },
        setAboutBrand: (state, action) => {
            state.aboutBrand = action.payload;
        }
    }
});

export const {setCompanyName, setIndustry, setMissionStatement, setVisionStatement, setAboutBrand} = brandSlice.actions;

export default brandSlice.reducer;
