import { styled } from "@mui/system";
import { Button } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#1636A0',
    fontFamily: 'montserrat, sans-serif',
    fontWeight: 600, // semibold
    color: '#ffffff',
    fontSize: '16px',
    textTransform: 'none',
    borderRadius: '10px',
    marginTop: '80px',
    width: '8vw',
    marginBottom: '20px',
    [theme.breakpoints.down('sm')]: {
        width: '100%',  // Full width on mobile
        marginTop: '40px',  // Reduce margin on mobile
        fontSize: '14px',  // Slightly smaller font size on mobile
    },
    '&:disabled': {
        backgroundColor: '#454545',
        color: 'white',
    },
    '&:hover': {
        backgroundColor: '#2255FF',
    },
}));

export default StyledButton;
