import axios from 'axios';
import config from '../config';
import {setBrandOptions} from '../store/sessionSlice';
import store from "../store/store";

export const getOptions = async (sessionId: string) => {
    const maxRetries = 3;
    let attempts = 0;

    while (attempts < maxRetries) {
        try {
            const data = {"sessionId": sessionId};
            const response = await axios.post(`${config.apiUrl}/v1/brand/session/options`, data);

            const brandOptions = response.data.data.options;

            if (brandOptions && Object.keys(brandOptions).length > 0) {
                store.dispatch(setBrandOptions(brandOptions));
                return response.data;
            } else {
                attempts += 1;
                console.warn(`Attempt ${attempts} failed: Retrying...`);
            }
        } catch (error) {
            console.error('Error getting options:', error);
            attempts += 1;
        }
    }

    throw new Error('Failed to get brand options after 3 retries.');
};

/*
{
    "data": {
        "options": {
            "colorOptions": {
                "option1": {
                    "primary": [
                        "#003f5c",
                        "#58508d",
                        "#bc5090",
                        "#ff6361",
                        "#ffa600"
                    ],
                    "secondary": [
                        "#005f73",
                        "#0a9396",
                        "#94d2bd",
                        "#e9d8a6",
                        "#ee9b00"
                    ],
                    "tertiary": [
                        "#6a0572",
                        "#a6036d",
                        "#d90368",
                        "#f25d27",
                        "#f4b942"
                    ],
                    "explanation": "These colors create a sense of modernity and technology. The primary palette has strong, deep tones to convey reliability. The secondary palette introduces more natural, calming colors to promote trust. The tertiary palette offers vibrant and energetic options to draw attention."
                },
                "option2": {
                    "primary": [
                        "#234e70",
                        "#fb743e",
                        "#ffa600",
                        "#82b3d6",
                        "#c3e7e3"
                    ],
                    "secondary": [
                        "#2b9348",
                        "#55a630",
                        "#80b918",
                        "#bfd200",
                        "#e9db4d"
                    ],
                    "tertiary": [
                        "#a31621",
                        "#ea3546",
                        "#f86624",
                        "#f9c80e",
                        "#43aa8b"
                    ],
                    "explanation": "Option 2 focuses on a combination of bold and lively colors to depict innovation and creativity. The primary colors are vivid and energetic, while secondary colors include vibrant greens adding freshness. The tertiary colors offer a splash of contrast."
                },
                "option3": {
                    "primary": [
                        "#0b3d91",
                        "#1c54a2",
                        "#2b69b2",
                        "#5472d3",
                        "#7888db"
                    ],
                    "secondary": [
                        "#0c3c70",
                        "#205493",
                        "#2679b7",
                        "#309dde",
                        "#42abe2"
                    ],
                    "tertiary": [
                        "#8b1e3f",
                        "#c0c1e2",
                        "#ae3e4e",
                        "#d66d75",
                        "#ffa7ab"
                    ],
                    "explanation": "This option maintains a professional yet accessible feel. The primary colors are blues, which are associated with intelligence and technology. Secondary colors complement the primary colors with brighter tones, while tertiary colors add a soft, approachable touch."
                }
            },
            "fonts": {
                "brand": {
                    "primary": {
                        "fontOption1": {
                            "name": "Roboto",
                            "explanation": "Roboto is a modern, clean font that is highly readable. It's perfect for contemporary brands, especially in tech spaces."
                        },
                        "fontOption2": {
                            "name": "Montserrat",
                            "explanation": "Montserrat offers a geometric style that stands out, ideal for a brand that wants to convey sophistication and innovation."
                        }
                    },
                    "secondary": {
                        "fontOption1": {
                            "name": "Open Sans",
                            "explanation": "Open Sans is neutral and works well for body text, providing a balance of readability and professionalism."
                        },
                        "fontOption2": {
                            "name": "Lato",
                            "explanation": "Lato has a semi-rounded design that makes it friendly and approachable for various applications."
                        }
                    },
                    "tertiary": {
                        "fontOption1": {
                            "name": "Raleway",
                            "explanation": "Raleway's elegance is suitable for headlines and special applications where a touch of uniqueness is required."
                        },
                        "fontOption2": {
                            "name": "Source Sans Pro",
                            "explanation": "Source Sans Pro is clean and easily legible, making it great for digital content and user interfaces."
                        }
                    }
                },
                "product": {
                    "primary": {
                        "fontOption1": {
                            "name": "IBM Plex Sans",
                            "explanation": "IBM Plex Sans offers a modern yet humanist feel, fitting for AI and tech products."
                        },
                        "fontOption2": {
                            "name": "Poppins",
                            "explanation": "Poppins is a geometric sans-serif with a modern touch, suitable for tech-oriented interfaces and branding."
                        }
                    },
                    "secondary": {
                        "fontOption1": {
                            "name": "Nunito",
                            "explanation": "Nunito is rounded and friendly, making it excellent for interfaces needing a more inviting feel."
                        },
                        "fontOption2": {
                            "name": "Ubuntu",
                            "explanation": "Ubuntu offers a unique character, ideal for products that want to stand out in the tech space."
                        }
                    },
                    "tertiary": {
                        "fontOption1": {
                            "name": "PT Sans",
                            "explanation": "PT Sans provides high readability and a formal touch suitable for diverse applications."
                        },
                        "fontOption2": {
                            "name": "Merriweather",
                            "explanation": "Merriweather with its tasteful serif design, adds a touch of tradition to modern product interfaces."
                        }
                    }
                }
            },
            "illustrations": {
                "illustrationOption1": {
                    "name": "Undraw",
                    "description": "Undraw offers customizable SVG illustrations suitable for tech and AI themes.",
                    "link": "https://undraw.co/"
                },
                "illustrationOption2": {
                    "name": "Humaaans",
                    "description": "Humaaans enables creating diverse human illustrations, perfect for illustrating user scenarios in tech.",
                    "link": "https://www.humaaans.com/"
                }
            },
            "iconography": {
                "iconographyOption1": {
                    "name": "Feather Icons",
                    "description": "Feather Icons provides a set of open-source icons that are simple yet highly customizable.",
                    "link": "https://feathericons.com/"
                },
                "iconographyOption2": {
                    "name": "Font Awesome",
                    "description": "Font Awesome is a popular icon set that includes a wide variety of icons suitable for AI and tech contexts.",
                    "link": "https://fontawesome.com/"
                }
            },
            "brandInfo": {
                "name": "Blursday",
                "domain": "AI/ML/Robotics",
                "missionStatement": "${mission_statement}",
                "visionStatement": "${vision_statement}",
                "whatDoWeDo": "${what_does_the_brand_do}"
            },
            "additional_info": "These guidelines provide a comprehensive visual identity framework for Blursday, tailored specifically for a brand operating within the AI/ML and Robotics sector."
        },
        "sessionId": "ae9nqhjV8YfSq54UQpdj"
    },
    "err": {}
}
 */