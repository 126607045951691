import {styled} from "@mui/system";
import {SxProps, Typography} from "@mui/material";
import React from "react";

const Heading5 = styled(Typography)({
    paddingBottom: '20px',
    textAlign: 'center',
    fontColor: '#000000',
    font: 'Montserrat'
});

interface Heading3ComponentProps {
    children: React.ReactNode;
    sx?: SxProps;
}

const Heading5Component: React.FC<Heading3ComponentProps> = ({children, sx}) => (
    <Heading5 variant="h5" sx={sx}>
        {children}
    </Heading5>
);

export default Heading5Component;