import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Container, CssBaseline} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {useDispatch, useSelector} from 'react-redux';
import {nextStep} from "./store/stepSlice";
import {startSession} from "./services/startSession";
import NavBar from './components/NavBar';
import BrandGenerator from './pages/BrandGenerator';
import IndustrySelector from './pages/IndustrySelector';
import MissionStatement from './pages/MissionStatement';
import VisionStatement from './pages/VisionStatement';
import AboutBrand from './pages/AboutBrand';
import ColourOptions from './pages/brand_options/ColourOptions';
import FontOptions from './pages/brand_options/FontOptions';
import IllustrationOptions from './pages/brand_options/IllustrationOptions';
import IconographyOptions from './pages/brand_options/IconographyOptions';
import BrandOptions from './pages/brand_options/SelectedBrandOptions';
import {datadogRum} from '@datadog/browser-rum';


const theme = createTheme();

const App = () => {
    const dispatch = useDispatch();
    const step = useSelector((state: any) => state.step.step);
    const [loading, setLoading] = useState(true);

    const handleNext = () => {
        dispatch(nextStep());
    };

    useEffect(() => {
        startSession()
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error('Error starting session:', error);
                setLoading(false);
            });

        datadogRum.init({
            applicationId: `${process.env.REACT_APP_DATADOG_APP_ID}`,
            clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.com',
            service: 'brand-builder',
            env: 'prod',
            // Specify a version number to identify the deployed version of your application in Datadog
            version: '1.0.0',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 40,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
        });
    }, []);

    if (loading) {
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                >
                    <CircularProgress/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <NavBar/>
            <Container>
                {step === 1 && <BrandGenerator onNext={handleNext}/>}
                {step === 2 && <IndustrySelector onNext={handleNext}/>}
                {step === 3 && <AboutBrand onNext={handleNext}/>}
                {step === 4 && <MissionStatement onNext={handleNext}/>}
                {step === 5 && <VisionStatement onNext={handleNext}/>}
                {step === 6 && <ColourOptions onNext={handleNext}/>}
                {step === 7 && <FontOptions onNext={handleNext}/>}
                {step === 8 && <IllustrationOptions onNext={handleNext}/>}
                {step === 9 && <IconographyOptions onNext={handleNext}/>}
                {step === 10 && <BrandOptions onNext={handleNext}/>}
            </Container>
        </ThemeProvider>
    );
};

export default App;
