import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setIndustry} from '../store/brandSlice';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import CenteredContainer from '../components/CenteredContainer';
import Heading3Component from '../components/Heading3';
import Body1Component from '../components/Body1';
import StyledButton from '../components/StyledButton';
import {recordAnswer} from '../services/brandAnswer';

interface IndustrySelectorProps {
    onNext: () => void;
}

const industries = [
    "Fintech",
    "EdTech",
    "AI/ML/Robotics",
    "MedTech"
];

const IndustrySelector: React.FC<IndustrySelectorProps> = ({onNext}) => {
    const dispatch = useDispatch();
    const industry = useSelector((state: any) => state.brand.industry);
    const companyName = useSelector((state: any) => state.brand.companyName);
    const sessionId = useSelector((state: any) => state.session.sessionId);
    const question = useSelector((state: any) => state.session.questions[1]);
    const [open, setOpen] = useState(false);
    const borderRadius = '10px';

    const handleIndustryChange = (event: SelectChangeEvent<unknown>) => {
        dispatch(setIndustry(event.target.value as string));
    };

    const handleToggleOpen = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleNextClick = async () => {
        try {
            await recordAnswer({sessionId: sessionId, questionId: question.questionId, answer: industry});
            onNext();
        } catch (error) {
            // Handle error (e.g., show error message)
        }
    };

    const IconComponent = open ? KeyboardArrowUp : KeyboardArrowDown;

    return (
        <CenteredContainer>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100vh',
                    alignItems: 'center',
                    padding: {xs: '16px', sm: '24px'} // Adjust padding for mobile
                }}
            >
                <Box sx={{flex: 1}}>
                    <Heading3Component sx={{paddingBottom: '30px'}}>
                        Hi {companyName}!
                    </Heading3Component>
                    <Body1Component sx={{fontSize: {xs: '24px', sm: '30px'}}}>
                        {question.question}
                    </Body1Component>
                    <Body1Component sx={{fontSize: {xs: '18px', sm: '20px'}, marginTop: '30px'}}>
                        Pick your turf, and get to work
                    </Body1Component>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', sm: 'row'}, // Stack items vertically on mobile
                            alignItems: 'center',
                            gap: '16px',
                            width: '100%',
                            paddingTop: '50px',
                        }}
                    >
                        <FormControl
                            variant="outlined"
                            margin="normal"
                            sx={{
                                width: {xs: '100%', sm: '40vw'}, // Full width on mobile, 40vw on desktop
                                paddingRight: {xs: 0, sm: '36px'}, // No padding on mobile
                            }}
                        >
                            <InputLabel>Choose your industry</InputLabel>
                            <Select
                                value={industry}
                                onChange={handleIndustryChange}
                                label="Choose your industry"
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                IconComponent={(props) => (
                                    <IconComponent
                                        {...props}
                                        onClick={handleToggleOpen}
                                        sx={{cursor: 'pointer', marginRight: '8px'}}
                                    />
                                )}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200, // Adjust the max height to prevent overflow
                                        },
                                    },
                                }}
                                sx={{textAlign: 'left'}} // Ensure text alignment in the dropdown
                            >
                                {industries.map((industryItem) => (
                                    <MenuItem
                                        key={industryItem}
                                        value={industryItem}
                                        sx={{
                                            textAlign: 'left',
                                            '&.Mui-selected': {
                                                backgroundColor: '#2255FF',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: '#2255FF',
                                                },
                                            },
                                        }}
                                    >
                                        {industryItem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={handleNextClick}
                            disabled={!industry.trim()}
                            sx={{
                                height: '56px',
                                borderRadius: borderRadius,
                                width: {xs: '100%', sm: '8vw'}, // Full width on mobile, 8vw on desktop
                                marginTop: {xs: '20px', sm: '27px'}, // Adjust margin for mobile
                            }}
                        >
                            Next
                        </StyledButton>
                    </Box>
                </Box>
                <Body1Component sx={{fontSize: {xs: '16px', sm: '20px'}, marginTop: 'auto', paddingBottom: '70px'}}>
                    PS: We’re supporting the following industries as of now, if you cannot find your industry please
                    reach out to us for a custom solution at founders@blursday.wtf
                </Body1Component>
            </Box>
        </CenteredContainer>
    );
};

export default IndustrySelector;
