import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMissionStatement } from '../store/brandSlice';
import CenteredContainer from '../components/CenteredContainer';
import StyledButton from '../components/StyledButton';
import StyledForm from '../components/StyledForm';
import Heading3Component from "../components/Heading3";
import Body1Component from "../components/Body1";
import { recordAnswer } from "../services/brandAnswer";
import { Box } from "@mui/material";
import StyledTextField from "../components/StyledTextField";

interface MissionStatementProps {
    onNext: () => void;
}

const commonHeight = '56px';
const borderRadius = '10px';

const MissionStatement: React.FC<MissionStatementProps> = ({ onNext }) => {
    const dispatch = useDispatch();
    const question = useSelector((state: any) => state.session.questions[2]);
    const sessionId = useSelector((state: any) => state.session.sessionId);
    const companyName = useSelector((state: any) => state.brand.companyName);
    const storedMission = useSelector((state: any) => state.brand.missionStatement); // Get mission from Redux store

    const [mission, setMission] = useState(storedMission || '');

    useEffect(() => {
        setMission(storedMission || '');
    }, [storedMission]);

    const handleSubmit = () => {
        dispatch(setMissionStatement(mission));
        recordAnswer({
            sessionId: sessionId,
            questionId: question.questionId,
            answer: mission
        }).catch(e => console.log(e));
        onNext();
    };

    return (
        <CenteredContainer>
            <Box sx={{ width: '100%' }}>
                <Heading3Component sx={{ paddingBottom: '30px' }}>
                    Hi {companyName}!
                </Heading3Component>
                <Body1Component>
                    {question.question}
                </Body1Component>
                <Body1Component sx={{ fontSize: '20px', marginTop: '20px', paddingBottom: '60px' }}>
                    If you need help figuring this out, it might be time to reconsider your life choices.
                    <br/>
                    (Mission statement is what do you want to achieve, btw)
                </Body1Component>
                <StyledForm>
                    <StyledTextField
                        placeholder="C’mon you can put together a few words. Or sentences. Or paragraphs.... You get the point."
                        variant="outlined"
                        value={mission}
                        onChange={(e) => setMission(e.target.value)}
                        multiline
                        rows={6}
                        sx={{
                            backgroundColor: !mission.trim() ? '#F7F7F8' : 'inherit'
                        }}
                    />
                </StyledForm>
                <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!mission.trim()}
                    sx={{ height: commonHeight, borderRadius: borderRadius, width: '8vw', marginTop: '80px' }}
                >
                    Next
                </StyledButton>
            </Box>
        </CenteredContainer>
    );
};

export default MissionStatement;
