import React, { useEffect, useState } from 'react';

interface SvgLoaderProps {
    src: string;
    style?: React.CSSProperties; // For inline styles
    alt?: string; // For accessibility
}

const SvgLoader: React.FC<SvgLoaderProps> = ({ src, style, alt }) => {
    const [svgContent, setSvgContent] = useState<string>('');

    useEffect(() => {
        fetch(src)
            .then(response => response.text())
            .then(data => {
                setSvgContent(data);
            });
    }, [src]);

    return (
        <div
            style={style} // Apply the styles
            dangerouslySetInnerHTML={{ __html: svgContent }}
            aria-label={alt} // Accessibility
        />
    );
};

export default SvgLoader;
