import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CenteredContainer from '../../components/CenteredContainer';
import Heading3Component from "../../components/Heading3";
import {Box, Card, CardContent, CircularProgress, Typography} from "@mui/material";
import Body1Component from "../../components/Body1";
import Heading5Component from "../../components/Heading5";
import StyledButton from "../../components/StyledButton";
import {setFontPalette} from "../../store/sessionSlice";
import WebFont from 'webfontloader';

interface FontOptionsProps {
    onNext: () => void;
}

type FontOption = {
    name: string;
    explanation: string;
    url?: string;
};

type FontOptions = {
    primary: { fontOption1: FontOption; fontOption2: FontOption };
    secondary: { fontOption1: FontOption; fontOption2: FontOption };
};

type SelectedCardState = {
    primary: { optionIndex: number | null; font: FontOption | null };
    secondary: { optionIndex: number | null; font: FontOption | null };
};

const FontOptions: React.FC<FontOptionsProps> = ({onNext}) => {
    const [fontOptions, setFontOptions] = useState<FontOptions | null>(null);
    const [loading, setLoading] = useState(true);
    const [selectedCard, setSelectedCard] = useState<SelectedCardState>({
        primary: {optionIndex: null, font: null},
        secondary: {optionIndex: null, font: null},
    });
    const [allSelected, setAllSelected] = useState(false);

    const sessionId = useSelector((state: any) => state.session.sessionId);
    const companyName = useSelector((state: any) => state.brand.companyName);
    const dispatch = useDispatch();
    const fontOption = useSelector((state: any) => state.session.brandOptions.fonts);

    useEffect(() => {
        setFontOptions(fontOption.brand);
        setLoading(false);
    }, [sessionId]);

    useEffect(() => {
        const {primary, secondary} = selectedCard;
        if (primary.font || secondary.font) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [selectedCard]);

    useEffect(() => {
        if (fontOptions) {
            const fontsToLoad = [
                ...Object.values(fontOptions.primary),
                ...Object.values(fontOptions.secondary),
            ].map(fontOption => fontOption.name);

            WebFont.load({
                google: {
                    families: fontsToLoad
                }
            });
        }
    }, [fontOptions]);

    function handleClick(
        optionIndex: number,
        category: keyof FontOptions,
        font: FontOption
    ) {
        setSelectedCard((prevState) => ({
            ...prevState,
            [category]: {
                optionIndex,
                font,
            },
        }));
    }

    function handleSubmit() {
        const {primary, secondary} = selectedCard;

        if (!primary.font) {
            handleClick(0, 'primary', fontOptions!.primary.fontOption1);
        }

        if (!secondary.font) {
            handleClick(0, 'secondary', fontOptions!.secondary.fontOption1);
        }

        dispatch(
            setFontPalette({
                brand: {
                    primary: selectedCard.primary.font || fontOptions!.primary.fontOption1,
                    secondary: selectedCard.secondary.font || fontOptions!.secondary.fontOption1,
                },
                product: {
                    primary: selectedCard.primary.font || fontOptions!.primary.fontOption1,
                    secondary: selectedCard.secondary.font || fontOptions!.secondary.fontOption1,
                }
            })
        );
        onNext();
    }

    const renderFontCard = (
        optionIndex: number,
        category: keyof FontOptions,
        fontOption: FontOption,
    ) => (
        <Card
            sx={{
                minWidth: '275px',
                margin: '10px',
                flexGrow: 1,
                maxWidth: '500px',
                border:
                    selectedCard[category].optionIndex === optionIndex &&
                    selectedCard[category].font?.name === fontOption.name
                        ? '2px solid blue'
                        : 'none',
                fontFamily: fontOption.name,
                textAlign: 'center', // Center text inside card
            }}
            onClick={() => handleClick(optionIndex, category, fontOption)}
        >
            <CardContent>
                <Typography sx={{fontSize: 14}} color="textSecondary" gutterBottom>
                    {category.charAt(0).toUpperCase() + category.slice(1)} Font
                </Typography>
                <Typography variant="h6" sx={{fontFamily: fontOption.name}}>{fontOption.name}</Typography>
                <Typography variant="body2" sx={{fontFamily: fontOption.name}}>{fontOption.explanation}</Typography>
            </CardContent>
        </Card>
    );

    const renderFontOptions = (
        options: FontOptions
    ) => (
        <Box sx={{marginBottom: '40px'}}>
            <Heading5Component>Option 1</Heading5Component>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'}, // Stack vertically on mobile
                    justifyContent: 'center',
                    alignItems: 'center', // Center items vertically
                    marginBottom: '20px',
                    gap: '16px', // Space between cards
                    flexWrap: 'wrap', // Wrap items on small screens
                    maxWidth: '100%' // Ensure it doesn’t exceed the container width
                }}
            >
                {renderFontCard(0, 'primary', options.primary.fontOption1)}
                {renderFontCard(0, 'secondary', options.secondary.fontOption1)}
            </Box>
            <Heading5Component>Option 2</Heading5Component>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'}, // Stack vertically on mobile
                    justifyContent: 'center',
                    alignItems: 'center', // Center items vertically
                    gap: '16px', // Space between cards
                    flexWrap: 'wrap', // Wrap items on small screens
                    maxWidth: '100%' // Ensure it doesn’t exceed the container width
                }}
            >
                {renderFontCard(1, 'primary', options.primary.fontOption2)}
                {renderFontCard(1, 'secondary', options.secondary.fontOption2)}
            </Box>
        </Box>
    );

    return (
        <CenteredContainer>
            <Heading3Component sx={{paddingBottom: '30px', textAlign: 'center'}}>
                Hi {companyName}!
            </Heading3Component>
            {loading ? (
                <>
                    <Body1Component sx={{paddingBottom: '60px', textAlign: 'center'}}>
                        We are generating your brand options. This may take a few seconds.
                    </Body1Component>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress/>
                    </Box>
                </>
            ) : (
                <Box sx={{textAlign: 'center'}}>
                    <Body1Component sx={{paddingBottom: '30px'}}>
                        Select two preferred fonts. One for each primary and secondary category from
                        the available options. (Again, scrolling helps.)
                    </Body1Component>
                    {fontOptions && (
                        <Box>
                            {renderFontOptions(fontOptions)}
                        </Box>
                    )}
                    <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!allSelected}
                            sx={{width: {xs: '100%', sm: 'auto'}}} // Full width on mobile
                        >
                            Next
                        </StyledButton>
                    </Box>
                </Box>
            )}
        </CenteredContainer>
    );
};

export default FontOptions;
