import { styled } from "@mui/system";
import { SxProps, Typography } from "@mui/material";
import React from "react";

const Body1 = styled(Typography)(({ theme }) => ({
    color: '#6A6A6A',
    font: 'Montserrat, sans-serif',
    fontSize: '1.25rem',  // Default for larger screens
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',  // Smaller font size for mobile
    },
}));

interface Body1ComponentProps {
    children: React.ReactNode;
    sx?: SxProps;
}

const Body1Component: React.FC<Body1ComponentProps> = ({ children, sx }) => (
    <Body1 variant="body1" gutterBottom sx={sx}>
        {children}
    </Body1>
);

export default Body1Component;
