import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CenteredContainer from '../../components/CenteredContainer';
import Heading3Component from "../../components/Heading3";
import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import Body1Component from "../../components/Body1";
import Heading5Component from "../../components/Heading5";
import StyledButton from "../../components/StyledButton";
import { setIllustrationOption } from "../../store/sessionSlice";
// @ts-ignore
import Microlink from "@microlink/react";

interface IllustrationOptionsProps {
    onNext: () => void;
}

type IllustrationOption = {
    name: string;
    description: string;
    link: string;
};

type IllustrationOptions = {
    illustrationOption1: IllustrationOption;
    illustrationOption2: IllustrationOption;
};

const IllustrationOptions: React.FC<IllustrationOptionsProps> = ({ onNext }) => {
    const [illustrationOptions, setIllustrationOptions] = useState<IllustrationOptions | null>(null);
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState<number | null>(null);

    const sessionId = useSelector((state: any) => state.session.sessionId);
    const companyName = useSelector((state: any) => state.brand.companyName);
    const illustrations = useSelector((state: any) => state.session.brandOptions.illustrations);
    const dispatch = useDispatch();

    useEffect(() => {
        setIllustrationOptions(illustrations);
        setLoading(false);
    }, [sessionId]);

    const handleClick = (optionIndex: number, illustration: IllustrationOption) => {
        setSelectedOption(optionIndex);
        dispatch(setIllustrationOption(illustration));
    };

    const renderIllustrationCard = (
        optionIndex: number,
        illustrationOption: IllustrationOption
    ) => (
        <Card
            sx={{
                minWidth: '275px',
                margin: '10px',
                flexGrow: 1,
                maxWidth: '500px',
                border: selectedOption === optionIndex ? '2px solid blue' : 'none',
                '@media (max-width: 600px)': {
                    maxWidth: '90vw', // Adjust the max width for mobile
                }
            }}
            onClick={() => handleClick(optionIndex, illustrationOption)}
        >
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
                    {illustrationOption.name}
                </Typography>
                <Typography variant="body2">{illustrationOption.description}</Typography>
                <Typography variant="body2" sx={{ marginBottom: '10px', color: 'blue', textDecoration: 'underline' }}>
                    <a href={illustrationOption.link} target="_blank" rel="noopener noreferrer">
                        {illustrationOption.link}
                    </a>
                </Typography>
                <Microlink url={illustrationOption.link} sx={{ width: '100%', height: '200px', border: 'none' }} />
            </CardContent>
        </Card>
    );

    const renderIllustrationOptions = (
        options: IllustrationOptions
    ) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', '@media (min-width: 600px)': { flexDirection: 'row', justifyContent: 'space-between' } }}>
            <Box sx={{ textAlign: 'center', marginBottom: '20px', '@media (min-width: 600px)': { marginBottom: '0' } }}>
                <Heading5Component>Option 1</Heading5Component>
                {renderIllustrationCard(0, options.illustrationOption1)}
            </Box>
            <Box sx={{ textAlign: 'center', marginBottom: '20px', '@media (min-width: 600px)': { marginBottom: '0' } }}>
                <Heading5Component>Option 2</Heading5Component>
                {renderIllustrationCard(1, options.illustrationOption2)}
            </Box>
        </Box>
    );

    const handleSubmit = () => {
        onNext();
    };

    return (
        <CenteredContainer>
            <Box sx={{ width: '100%' }}>
                <Heading3Component sx={{ paddingBottom: '30px' }}>
                    Hi {companyName}!
                </Heading3Component>
                {loading ? (
                    <>
                        <Body1Component sx={{ paddingBottom: '60px' }}>
                            We are generating your brand options. This may take a few seconds.
                        </Body1Component>
                        <CircularProgress />
                    </>
                ) : (
                    <Box>
                        <Body1Component sx={{ paddingBottom: '30px' }}>
                            Pick an illustration option that you like the best:
                        </Body1Component>
                        {illustrationOptions && (
                            <Box>
                                {renderIllustrationOptions(illustrationOptions)}
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <StyledButton
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={selectedOption === null}
                            >
                                Next
                            </StyledButton>
                        </Box>
                    </Box>
                )}
            </Box>
        </CenteredContainer>
    );
};

export default IllustrationOptions;
