import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setVisionStatement} from '../store/brandSlice';
import CenteredContainer from '../components/CenteredContainer';
import StyledButton from '../components/StyledButton';
import StyledForm from '../components/StyledForm';
import Heading3Component from "../components/Heading3";
import Body1Component from "../components/Body1";
import {recordAnswer} from "../services/brandAnswer";
import {Box, CircularProgress} from "@mui/material";
import StyledTextField from "../components/StyledTextField";
import {getOptions} from "../services/getOptions";

interface VisionStatementProps {
    onNext: () => void;
}

const commonHeight = '56px';
const borderRadius = '10px';

const VisionStatement: React.FC<VisionStatementProps> = ({onNext}) => {
    const dispatch = useDispatch();
    const question = useSelector((state: any) => state.session.questions[3]);
    const sessionId = useSelector((state: any) => state.session.sessionId);
    const companyName = useSelector((state: any) => state.brand.companyName);
    const storedVision = useSelector((state: any) => state.brand.visionStatement); // Get vision from Redux store

    const [vision, setVision] = useState(storedVision || '');
    const [loading, setLoading] = useState(false); // Add loading state

    useEffect(() => {
        setVision(storedVision || '');
    }, [storedVision]);

    const handleSubmit = () => {
        setLoading(true); // Set loading to true when the API call starts
        dispatch(setVisionStatement(vision));
        recordAnswer({
            sessionId: sessionId,
            questionId: question.questionId,
            answer: vision
        }).then(() => {
                getOptions(sessionId).then(() => {
                    setLoading(false); // Set loading to false when the API call is complete
                    onNext();
                }).catch(e => {
                    console.log(e);
                    setLoading(false); // Set loading to false in case of error
                })
            }
        ).catch(
            e => {
                console.log(e);
                setLoading(false);
            }
        );
    };

    return (
        <CenteredContainer>
            <Box sx={{width: '100%'}}>
                <Heading3Component sx={{paddingBottom: '30px'}}>
                    Hi {companyName}!
                </Heading3Component>
                <Body1Component>
                    {question.question}
                </Body1Component>
                <Body1Component sx={{paddingBottom: '60px', marginTop: '20px', fontSize: '20px'}}>
                    Tell us where you see your empire in 10 years. Crystal balls welcome.
                </Body1Component>
                <StyledForm>
                    <StyledTextField
                        placeholder="Unlike your investor, we don’t charge equity for your dreams"
                        variant="outlined"
                        value={vision}
                        onChange={(e) => setVision(e.target.value)}
                        multiline
                        rows={6}
                        sx={{
                            backgroundColor: !vision.trim() ? '#F7F7F8' : 'inherit'
                        }}
                    />
                </StyledForm>
                {loading ? ( // Show loader when loading
                    <>
                        <Body1Component sx={{paddingBottom: '60px'}}>
                            We are generating your brand options. This may take a few seconds.
                        </Body1Component>
                        <CircularProgress sx={{marginTop: '80px'}}/>
                    </>
                ) : (
                    <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!vision.trim()}
                        sx={{height: commonHeight, borderRadius: borderRadius, width: '8vw', marginTop: '80px'}}
                    >
                        Next
                    </StyledButton>
                )}
            </Box>
        </CenteredContainer>
    );
};

export default VisionStatement;
