const config = {
    development: {
        apiUrl: "http://localhost:8000/api",
    },
    production: {
        apiUrl: "https://api.blursday.xyz/api",
    }
};

export default config.production;
