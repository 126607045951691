// src/store/stepSlice.ts
import {createSlice} from '@reduxjs/toolkit';

interface StepState {
    step: number;
}

const initialState: StepState = {
    step: 1,
};

const stepSlice = createSlice({
    name: 'step',
    initialState,
    reducers: {
        nextStep(state) {
            state.step += 1;
        },
        resetStep(state) {
            state.step = 1;
        },
        previousStep(state) {
            state.step -= 1;
        }
    },
});

export const {nextStep, resetStep, previousStep} = stepSlice.actions;
export default stepSlice.reducer;