import { styled } from "@mui/system";
import { SxProps, Typography } from "@mui/material";
import React from "react";

const Heading3 = styled(Typography)(({ theme }) => ({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    fontColor: '#000000',
    font: 'Montserrat, sans-serif',
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',  // Smaller font size for mobile
    },
}));

interface Heading3ComponentProps {
    children: React.ReactNode;
    sx?: SxProps;
}

const Heading3Component: React.FC<Heading3ComponentProps> = ({ children, sx }) => (
    <Heading3 variant="h3" gutterBottom sx={sx}>
        {children}
    </Heading3>
);

export default Heading3Component;
