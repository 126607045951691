import { styled } from "@mui/system";
import { Box } from "@mui/material";

const StyledForm = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '66px',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'stretch',
        flexDirection: 'column',  // Stack items vertically on mobile
        gap: '20px',  // Reduce gap on mobile
        width: '100%',  // Ensure full width on mobile
        fontSize: '14px',
        '& .MuiInputLabel-root': {
            fontSize: '0.875rem',  // Reduce label font size on mobile
        },
        '& .MuiInputBase-input': {
            fontSize: '0.875rem',  // Reduce input font size on mobile
        },

    },
}));

export default StyledForm;


/*
import { styled } from "@mui/system";
import { TextField } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: '100%',  // Full width on mobile
    [theme.breakpoints.down('sm')]: {
        '& .MuiInputLabel-root': {
            fontSize: '0.875rem',  // Reduce label font size on mobile
        },
        '& .MuiInputBase-input': {
            fontSize: '0.875rem',  // Reduce input font size on mobile
        },
    },
}));

export default StyledTextField;
*/
